import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CognitoService } from 'src/app/core/services/cognito/cognito.service';
import { NotificationService } from 'src/app/core/services/notification/notification.service';
import { RestCallService } from 'src/app/core/services/rest-call/rest-call.service';
import { TranslateApplicationService } from 'src/app/core/translate/translate-application-service';
import { IAppoitmentType } from '../Interfaces/IAppoitmentType';
import { ICostCenter } from '../Interfaces/ICostCenter';
import { IEmployee } from '../Interfaces/IEmployee';
import { IPosition } from '../Interfaces/IPosition';
import { IRelationship } from '../Interfaces/IRelationship';
import { IUsers } from '../Interfaces/IUsers';

@Injectable({
	providedIn: 'root',
})
export class EmployeeService extends RestCallService {
	data: IEmployee = {
		pkemployee: 0,
		employeename: '',
		employeenumber: 0,
		fkcostcenter: '',
		fkcell: 0,
		fksubarea: '',
		fkposition: '',
		fkshift: '',
		supervisornumber: 0,
		sypervisorname: '',
		fkemployeetype: '',
		costCenterName: '',
		positionName: '',
		username: '',
		lastupdated: new Date(),
		strlastupdated: '',
		email: '',
		available: false,
		badgenumber: 0,
		isBadge: false
	};
	dataUser: IUsers = {
		pkuser: 0,
		fkemployeenumber: 0,
		employeename: '',
		windowsuser: '',
		email: '',
		available: false,
		fkuserupdater: 0,
		userupdatername: '',
		lastupdated: new Date(),
		strlastupdated: '',
		isBadge: false,
		username: ''
	};

	constructor(
		public httpClient: HttpClient,
		private cognito: CognitoService,
		public toastr: ToastrService,
		public notificationService: NotificationService,
		public translate: TranslateApplicationService,
	) {
		super(
			httpClient,
			'APIMedicApp',
			toastr,
			notificationService,
			translate,
		);
	}

	public GetListAdmin() {
		if (window.localStorage.getItem('isbadge') == null && !this.cognito.IsAuhtenticated())
			this.cognito.LogOut();
			
		return this.get<IUsers[]>('/api/Employees/GetAdmins');
	}

	//EMPLOYEES
	public GetListEmployees() {
		if (window.localStorage.getItem('isbadge') == null && !this.cognito.IsAuhtenticated())
			this.cognito.LogOut();

		return this.get<IEmployee[]>('/api/Employees/GetEmployees');
	}

	public GetInfoEmployees() {
		if (window.localStorage.getItem('isbadge') == null && !this.cognito.IsAuhtenticated())
			this.cognito.LogOut();

		if(window.localStorage.getItem('isbadge') != null){
			this.data.isBadge = true;
		}

		return this.post<IEmployee[]>('/api/Employees/GetEmployeInformartion', this.data);
	}

	public GetCostCenter(costCenter: string) {
		if (window.localStorage.getItem('isbadge') == null && !this.cognito.IsAuhtenticated())
			this.cognito.LogOut();

		return this.post<ICostCenter[]>('/api/Employees/GetCostCenter?costCenterNumber=' + costCenter);
	}

	public GetPosition(position: string) {
		if (window.localStorage.getItem('isbadge') == null && !this.cognito.IsAuhtenticated())
			this.cognito.LogOut();

		return this.post<IPosition[]>('/api/Employees/GetPosition?positionNumber=' + position);
	}

	public GetRelationship() {
		if (window.localStorage.getItem('isbadge') == null && !this.cognito.IsAuhtenticated())
			this.cognito.LogOut();

		return this.get<IRelationship[]>('/api/Employees/GetRelationship');
	}

	public EmployeeHasPermissionToAdminScreen() {
		if (window.localStorage.getItem('isbadge') == null && !this.cognito.IsAuhtenticated())
			this.cognito.LogOut();

		if(window.localStorage.getItem('isbadge') != null){
			this.data.isBadge = true;
			this.data.username = window.localStorage.getItem('username');
		}

		return this.post<IEmployee[]>('/api/Employees/EmployeeHasPermissionToAdminScreen', this.data);
	}

	public ChangeStatusCategory(data: any) {
		if (window.localStorage.getItem('isbadge') == null && !this.cognito.IsAuhtenticated())
			this.cognito.LogOut();
						
		if(window.localStorage.getItem('isbadge') != null){
			this.dataUser.username = window.localStorage.getItem('username')
			this.dataUser.isBadge = true;
		}

		this.dataUser.pkuser = data.pkuser;
		this.dataUser.available = data.available;
		return this.put('/api/Employees/ChangeStatusCategory', this.dataUser);
	}

	public CreateEmployee(data: any) {
		if (window.localStorage.getItem('isbadge') == null && !this.cognito.IsAuhtenticated())
			this.cognito.LogOut();

		if(window.localStorage.getItem('isbadge') != null){
			this.dataUser.username = window.localStorage.getItem('username')
			this.dataUser.isBadge = true;
		}

		this.dataUser.employeename = data.txtEmployeeName;
		this.dataUser.fkemployeenumber = data.txtEmployeeNumber;
		this.dataUser.windowsuser = data.txtUsername;
		this.dataUser.email = data.txtEmail;
		this.dataUser.available = true;

		return this.post('/api/Employees/CreateEmployee', this.dataUser);
	}

	public GetBadge(data: any) {
		if(window.localStorage.getItem('isbadge') != null){
			this.data.isBadge = true;
			this.data.username = window.localStorage.getItem('username');
		}
		
		this.data.badgenumber = data.badgenumber;
		return this.post<IEmployee[]>('/api/Employees/GetBadge', this.data);
	}
}
