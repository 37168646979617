import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import {
	HttpClient,
	HttpClientModule,
	HTTP_INTERCEPTORS,
} from '@angular/common/http';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './core/modules/shared.module';
import { MainModule } from './main/main.module';
import { AppSplashScreenService } from './core/services/splash-screen.service';
import { AppConfigService } from './core/services/config.service';
import { NavigationService } from './core/components/navigation/navigation.service';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { Error404Component } from './main/errorsPages/404/error-404.component';
import { InterceptorService } from './core/services/rest-call/interceptor.service';
import { ToastrService, ToastrModule } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { CustomPrimengModule } from '@jabil/ui-ng';
import { TranslateApplicationService } from './core/translate/translate-application-service';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CognitoGuard } from './core/cognito-guard/cognito-guard';
import { CognitoService } from './core/services/cognito/cognito.service';

export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http);
}

@NgModule({
	declarations: [AppComponent, Error404Component],
	imports: [
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		BrowserAnimationsModule,
		SharedModule,
		ReactiveFormsModule,
		FormsModule,
		ToastrModule.forRoot({
			timeOut: 3000,
			positionClass: 'toast-top-right',
		}),
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient],
			},
		}),
		MainModule,
		CustomPrimengModule,
	],
	providers: [
		AppSplashScreenService,
		AppConfigService,
		NavigationService,
		ToastrService,
		DatePipe,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: InterceptorService,
			multi: true,
		},
		TranslateApplicationService,
		CognitoGuard,
		CognitoService,
	],
	bootstrap: [AppComponent],
	exports: [],
})
export class AppModule {}
