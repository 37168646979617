import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'jabil-button',
  templateUrl: './jabil-button.component.html',
  styleUrls: ['./jabil-button.component.scss']
})
export class JabilButtonComponent implements OnInit {
  @Output() eventClick = new EventEmitter<any>();
  @Input() caption: string = "";
  @Input() className: string = "";

  constructor() { 
    //This method is empty on purpose
  }

  ngOnInit(): void {
    //This method is empty on purpose
  }

  action() {
    this.eventClick.emit();
  }

}
