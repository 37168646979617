import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CognitoGuard } from './core/cognito-guard/cognito-guard';
import { AppComponent } from './app.component';
import { ContentComponent } from './main/content/content.component';
import { Error404Component } from './main/errorsPages/404/error-404.component';
import { MainComponent } from './main/main.component';

const routes: Routes = [
  {
    path: 'public',
    loadChildren: () => import('./main/content/public/public.module').then(mod => mod.PublicModule)
  },
  { 
    path: 'main',
    loadChildren: () => import('./main/content/sections/sections.module').then(mod => mod.SectionsModule)
  },
  { path: '',
    redirectTo: 'main',
    pathMatch: 'full' },
  {
    path: '**',
    component: Error404Component
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
