import { MenuIntemInterface } from './interfaces/menu-item.interface';
import { MenuCollapseInterface } from './interfaces/menu-collapse.interface';
import { MenuGroupInterface } from './interfaces/menu-group.interface';

export class AppNavigationModel {
	public model: any[];

	constructor() {
		this.model = [
			{
				id: 'applications',
				title: 'Applications',
				translate: 'MedicApp',
				type: 'group',
				icon: 'apps',
        children: [
					{
						id: 'Home',
						title: 'Home',
						translate: 'NAV.Home',
						type: 'item',
						url: '/main/home',
						icon: 'home',
					},
					{
						id: 'dashboards',
						title: 'Dashboards',
						translate: 'NAV.Appointments',
						type: 'collapse',
						icon: 'schedule',
						children: [
							{
								id: 'MySelf',
								title: 'MySelf',
								translate: 'NAV.My_self',
								type: 'item',
								url: '/main/Employee',
								icon: 'person',
							},
							{
								id: 'Familiar',
								title: 'Familiar',
								translate: 'NAV.Familiar',
								type: 'item',
								url: '/main/Familiar',
								icon: 'family_restroom',
							},
						],
					},
					{
						id: 'Catalogs',
						title: 'Catalogs',
						translate: 'NAV.Catalogs',
						type: 'collapse',
						icon: 'dashboard',
            children: [
              {
                id: 'DateBlocking',
                title: 'Date Blocking',
                translate: 'NAV.Date_blocking',
                type: 'item',
                url: '/main/DateBlocking',
                icon: 'alarm_off',
              },
              {
                id: 'Employees',
                title: 'Employees',
                translate: 'NAV.Employees',
                type: 'item',
                url: '/main/Employees',
                icon: 'people_alt',
              },
						],
					},
				],
			},
			{
				id: 'About',
				title: 'About',
				translate: 'NAV.About',
				type: 'collapse',
				icon: 'help',
				children: [
					{
						id: 'Contact',
						title: 'Contact',
						translate: 'NAV.Contact_title',
						type: 'item',
						url: '/main/contact',
						icon: 'phone',
					},
					{
						id: 'Releases',
						title: 'Last release',
						translate: 'NAV.Last_release',
						type: 'item',
						url: '/main/lastrelease',
						icon: 'refresh',
					},
				],
			},
		];
	}
}
