import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
	selector: 'button-save',
	templateUrl: './button-save.component.html',
	styleUrls: ['./button-save.component.scss'],
})
export class ButtonSaveComponent implements OnInit {
	@Output() eventClick = new EventEmitter<any>();
	@Input() disable: boolean;
	@Input() caption: string = '';

	constructor() {
		//This method is empty on purpose
	}

	ngOnInit(): void {
		//This method is empty on purpose
	}

	Save() {
		this.eventClick.emit();
	}
}
