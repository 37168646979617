import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'button-add',
	templateUrl: './button-add.component.html',
	styleUrls: ['./button-add.component.scss'],
})
export class ButtonAddComponent{
	@Output() eventClick = new EventEmitter<any>();

	add() {
		this.eventClick.emit();
	}
}
