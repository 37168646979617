import { Component } from '@angular/core';
import { AppSplashScreenService } from './core/services/splash-screen.service';
import { NavigationService } from './core/components/navigation/navigation.service';
import { AppNavigationModel } from './navigation/navigation.model';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  
  constructor(
    private appSplashScreen: AppSplashScreenService,
    private appNavigationService: NavigationService,
    private titleService: Title
  ) {
    //this.appNavigationService.setNavigationModel(new AppNavigationModel());
    // this.titleService.setTitle(window["configuration"].applicationName);
  }
}
