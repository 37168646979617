import { Injectable } from '@angular/core';
import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor,
	HttpHeaders,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { CognitoService } from '../cognito/cognito.service';

@Injectable({
	providedIn: 'root',
})
export class InterceptorService implements HttpInterceptor {
	constructor(private tokenService: CognitoService) {}

	intercept(
		req: HttpRequest<any>,
		next: HttpHandler,
	): Observable<HttpEvent<any>> {
		
		//Public requests endpoints
		let reqs = ['EmployeesMainApi', 'assets/i18n/', 'applications-info', 'translations',
			'GetProperty', 'GetAvailableEnvironments', 'GetLastReleases', 'GetBadge'];

		//If user logged in with badge does not have an authentication token
		if(window.localStorage.getItem('isbadge') != null){
			return next.handle(req);
		}

		//If the requests includes one of the public requests endpoints does not need authentication token
		if (reqs.some(t=>req.url.includes(t))) {
			return next.handle(req);
		}

		const urlBase = req.headers.get('urlBase');

		//Setting the requests headers
		let headersNew = new HttpHeaders();

		req.headers.keys().forEach((key) => {
			if (key !== 'urlBase') {
				headersNew = headersNew.append(key, req.headers.get(key));
			}
		});

		return this.tokenService.GetAccessToken().pipe(
			mergeMap((token: string) => {
				const authorizedRequest = req.clone({
					headers: headersNew.append(
						'Authorization',
						`Bearer ${token}`,
					),
				});
				
				const expiry = JSON.parse(atob(token.split('.')[1])).exp;

				let now = (Date.now() / 1000);
				
				if (now >= expiry) {
					this.tokenService.LogOut();
					return next.handle(authorizedRequest);
				}
				else {
					return next.handle(authorizedRequest);
				}
			}),
		);
	}
}
