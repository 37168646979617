import { Component } from '@angular/core';
import {
	NavigationEnd,
	NavigationStart,
	Router,
	RouteReuseStrategy,
} from '@angular/router';
import { AppConfigService } from '../../core/services/config.service';
import { AppTranslationLoaderService } from 'src/app/core/services/translation-loader.service';
import { LogService } from '../content/sections/websocketclient/notification.services';
import { NotificationService } from '../../core/services/notification/notification.service';
import { ApplicationInfoService } from '../../core/translate/application-info.service';
import { TransactionResult } from '../../core/services/interfaces/transaction-result';
import { AppsInfo } from '../../core/translate/models/app-info';
import { environment } from '../../../environments/environment';
import { TranslateApplicationService } from '../../core/translate/translate-application-service';
import { Application } from '../../core/translate/models/application';
import { CognitoService } from '../../core/services/cognito/cognito.service';
import { NavigationService } from 'src/app/core/components/navigation/navigation.service';
import { AppNavigationModel } from 'src/app/navigation/navigation.model';
import { EmployeeService } from '../content/sections/services/employee-service';
import { IEmployee } from '../content/sections/Interfaces/IEmployee';
declare let jQuery: any;
@Component({
	selector: 'app-toolbar',
	templateUrl: './toolbar.component.html',
	styleUrls: ['./toolbar.component.scss']
})
export class AppToolbarComponent {
	userStatusOptions: any[];
	languages: Array<any> = [];
	selectedLanguage: any;
	showLoadingBar: boolean;
	horizontalNav: boolean;
	username: string;
	isAuthenticated: boolean;

	constructor(
		private router: Router,
		private appConfig: AppConfigService,
		private translate: TranslateApplicationService,

		private appNavigationService: NavigationService,
		private applications: ApplicationInfoService,
		private cognitoService: CognitoService,
		private EmployeeService: EmployeeService,
	) {
		this.applications.getApplicationByName().subscribe(
			(result: Application) => {
				result.languages.forEach((p) => {
					let language = {
						id: p.id,
						title: p.language,
						flag: p.code,
						applicationId: result.id,
					};
					this.languages.push(language);
				});

				this.selectLanguage();
			},
			(error) => {

			},
		);

		router.events.subscribe((event) => {
			if (event instanceof NavigationStart) {
				this.showLoadingBar = true;
			}
			if (event instanceof NavigationEnd) {
				this.showLoadingBar = false;
			}
		});

		this.appConfig.onSettingsChanged.subscribe((settings) => {
			this.horizontalNav = settings.layout.navigation === 'top';
		});

    this.locationPublic();
  }
  
  locationPublic() {
    if (!window.location.href.includes('public')) {
			if (window.localStorage.getItem('isbadge') == 'true') {
				this.username = window.localStorage.getItem('email');
				this.appNavigationService.setNavigationModel(
					new AppNavigationModel(),
				);
			} else {
        this.chooseEnviroment();
			}
		}
  }

  chooseEnviroment() {
    if (this.cognitoService.IsAuhtenticated()) {
      this.cognitoService.GetUserInfo().subscribe((result) => {
		debugger;
        this.username = result['email'];
        let isDev = window['configuration'].isDev;
        let userNT = '';
        if (isDev) {
          //DEV
          userNT = result['cognito:username'];
          userNT = userNT.slice(5);
        } else {
          // STG | PRD
          userNT = result['custom:samaccountname'];
        }

        localStorage.setItem('username', userNT);

        let email = result['email'];
        localStorage.setItem('email', email);
        this.appNavigationService.setNavigationModel(
          new AppNavigationModel(),
        );
      });
    }
  }

	Reload(): void {
		//Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
		//Add 'implements AfterViewInit' to the class.

		if (window.localStorage.getItem('isbadge') == 'true') {
			this.username = window.localStorage.getItem('email');
			this.appNavigationService.setNavigationModel(
				new AppNavigationModel(),
			);
		} else {
			if (this.cognitoService.IsAuhtenticated()) {
        this.cognitoService.GetUserInfo().subscribe((result) => {
					this.username = result['email'];
					let isDev = window['configuration'].isDev;
					let userNT = '';
					if (isDev) {
						//DEV
						userNT = result['cognito:username'];
						userNT = userNT.slice(5);
					} else {
						// STG | PRD
						userNT = result['custom:samaccountname'];
					}

					localStorage.setItem('username', userNT);

					let email = result['email'];
					localStorage.setItem('email', email);
					this.appNavigationService.setNavigationModel(
						new AppNavigationModel(),
					);
				});
			}
		}
	}

	selectLanguage(): void {
		let indexLang = 0;
		if (this.languages != undefined) {
			if (navigator.language.split('-').length > 0) {
				indexLang = this.languages.findIndex(
					(lang) => lang.flag === navigator.language.split('-')[0],
				);
			} else {
				indexLang = this.languages.findIndex(
					(lang) => lang.flag === navigator.language,
				);
			}

			if (indexLang < 0) {
				this.selectedLanguage = this.languages[0];
			} else {
				this.selectedLanguage = this.languages[indexLang];
			}

			this.setLanguage(this.selectedLanguage);
		}
	}

	setLanguage(lang) {
		// Set the selected language for toolbar
		this.selectedLanguage = lang;
		// selected language for translations
		this.translate.use(lang);
	}

	logOut(): void {
		//if (this.cognitoService.IsAuhtenticated()) {
		localStorage.removeItem('username');
		localStorage.removeItem('email');
		localStorage.removeItem('isbadge');
		this.cognitoService.LogOut();
		//}
	}
}
