import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { SettingsService } from '../content/sections/services/settings-service';
import { TransactionResult } from '../../core/services/interfaces/transaction-result';
import { ISettings } from '../content/sections/Interfaces/ISettings';
import { EnvironmentsService } from '../content/sections/services/environments-service';
import { IEnvironments } from '../content/sections/Interfaces/IEnvironments';

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit{
  ENVIRONMENT: string;
  public production: boolean;
  public APP_VERSION: string;

  constructor(
    private settingsService: SettingsService,
    private environmentsService: EnvironmentsService) {
      this.production = environment.production;
    }

  ngOnInit(): void {
    this.getAppVersion();
  }

  getAppVersion() {
    this.settingsService.GetProperty('APP_VERSION').subscribe(
      (result: TransactionResult<ISettings[]>) => {
        if (result.success) {
          this.APP_VERSION = result.data[0].settingvalue;
        }
      }
    );

    this.environmentsService.GetActiveEnvironment().subscribe(
			(result: TransactionResult<IEnvironments[]>) => {
			  if (result.success) {
				this.ENVIRONMENT = result.data[0].environment;
			  }
			}
		  );
  }
}
